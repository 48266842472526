.header-bottom {
  width: 100%;
  height: 100%;
  img {
    position: absolute;
    max-width: 100vw;
    height: auto;
    z-index: 100;
    bottom: 0;
    left: 0;
  }
  .white-curve {
    max-height: 45%;
    margin: 0 0 -30px -30px;
    &.white-curve-contact {
      max-height: 70%;
    }
    @media only screen and (max-width: 430px) {
      & {
        display: none;
      }
    }
  }

  @media only screen and (min-width: 770px) {
    img {
      max-width: 60vw;
    }
  }
}
