.button-primary {
  button {
    width: 170px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    margin-top: 15px;
    background: none;
    color: #ffffff;
    font-size: 16px;
    line-height: 40px;
    &:hover {
      background: rgba(255, 141, 39, 0.51);
    }
    &:focus {
      background: #ff8d27;
    }
    @media only screen and (max-width: 426px) {
      & {
        font-size: 14px;
      }
    }
  }
}
