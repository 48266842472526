.navbar {
  display: flex;
  z-index: 200;

  justify-content: first baseline;

  img {
    cursor: pointer;
  }

  .cancel,
  .toggler {
    height: 40px;
    width: 40px;
  }
  .footer-links {
    color: white;
    display: flex;

    a {
      padding-left: 50px;
      font-size: 22px;
      text-decoration: none;
      color: white;
      &.active {
        color: #ff8d27;
      }
    }
  }
}
