.offshore {
  min-height: 300px;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media only screen and (min-width: 800px) {
    & {
      min-height: 400px;
      margin: 30px 0;
    }
  }
}
