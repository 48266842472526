.characteristics {
  .about-wrapper,
  .list-wrapper {
    padding: 0 !important;
  }
  .list-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  height: auto;

  margin: 100px 0;
}
