.container {
  padding: 0;
  padding: 0 10%;
  max-width: 100vw;
}
input,
textarea {
  outline: 0;
}
* {
  box-sizing: border-box;
  font-family: "Cairo", sans-serif;
  font-weight: 400;
}
