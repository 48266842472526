.characteristic-list-offshore {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 680px;
  width: 90%;
  @media only screen and (max-width: 806px) {
    width: 100%;
    min-height: 740px;
  }
}
