.input-field {
  input,
  textarea {
    height: 70px;
    background: #f2f2f2;
    width: 100%;
    padding: 0 3vw;
    color: #333333;
    font-size: 23px;
    border: 0;
    margin-bottom: 15px;
    line-height: 30px;
    &::placeholder {
      color: #333333;
    }
  }
  textarea {
    height: 400px;
    padding: 2vw 3vw;
    resize: none;
  }
  @media only screen and (max-width: 500px) {
    input,
    textarea {
      font-size: 16px;
      padding: 0 25px;
      height: 45px;
    }
    textarea {
      height: 150px;
    }
  }
}
