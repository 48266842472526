.characteristic-list-items-wrapper {
  position: relative;
  padding: 0 8%;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  .characteristic-list-items {
    height: 210px;
    // padding: 20px;

    background-size: 110px;
    background-repeat: no-repeat;
    background-position: left center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    .square {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 20px;
      width: 20px;
      background-color: #ff8d27;
    }

    p {
      color: #333;
    }
  }
  @media only screen and (max-width: 806px) {
    & {
      padding: 10px 40px;
      .characteristic-list-items {
        .list-item-wrapper {
          background-color: rgba(255, 255, 255, 0.85);
        }
        margin: 0;
        background-position: center;
      }
    }
  }
}
