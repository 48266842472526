.who-are-we {
  min-height: 100vh;
  position: relative;
  .leader-wrapper {
    display: flex;
    flex-direction: row;
    .connect-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 200;
      .connect {
        margin: 10% 0;
        display: flex;
        img {
          height: 40px;
          margin-right: 25px;
        }
        .anchor {
          text-decoration: underline;

          font-size: 22px;
          a {
            color: white;
          }
        }
      }
    }
    .leader-image {
      text-align: end;
    }
    .leader {
      max-height: 70vh !important;
      max-width: 100%;
      margin-bottom: 60px;
    }
  }
}
