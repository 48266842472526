.sub-text {
  p {
    color: white;
    font-size: 21px;
    margin-top: 5px;
    font-weight: 400;
  }
  &.small {
    p {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 900px) {
    p {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 600px) {
    p {
      font-size: 13px !important;
    }
  }
}
