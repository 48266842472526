.button-secondary {
  button {
    width: 200px;
    border: 2px solid #ff8d27;
    box-sizing: border-box;
    margin-top: 15px;
    background: none;
    color: #ff8d27;
    font-size: 30px;
    line-height: 60px;
    &:hover {
      background: rgba(255, 141, 39, 0.13);
    }
    &:focus {
      background: #ff8d27;
      color: white;
    }
    @media only screen and (max-width: 426px) {
      & {
        width: 230px;
      }
    }
  }
}
