.nav-menu {
  background: #333333;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 5% !important;

  a {
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    text-decoration: none;
    color: #ffffff;

    &.active {
      color: #ff8d27;
    }
  }
  @media only screen and (max-width: 770px) {
    a {
      font-size: 50px;
      line-height: 75px;
      color: rgb(255, 255, 255) !important;
      &.active {
        // color: #333333;
        font-weight: 800;
      }
    }
    & {
      background: #ff8d27;
      justify-content: center;
    }
  }
}
