.project-list-item {
  padding: 7% 0;
  p {
    color: #333;
  }

  h1 {
    font-weight: 800;
  }
  img {
    width: 100%;
  }
}
