.header {
  background: #333333 !important;
  position: relative;
  max-height: auto;
  min-height: 75vh;
  padding: 40px 0;
  margin: 0;
  @media only screen and (max-width: 450px) {
    & {
      min-height: 30vh;
    }
  }
}
