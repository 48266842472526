.banner {
  max-width: 70%;
  height: 450px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  max-height: 480px;
  justify-content: flex-end;
  @media only screen and (max-width: 450px) {
    & {
      height: 290px;
      max-width: 90%;
    }
  }
}
.contact {
  justify-content: center;
  height: 300px;
  @media only screen and (max-width: 450px) {
    & {
      height: 150px;
      justify-content: flex-end;
      padding-bottom: 3%;
    }
    h1 {
      font-size: 27px;
    }
    p {
      font-size: 16px !important;
      line-height: 20px;
    }
  }
}
