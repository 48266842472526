.header-top {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  img {
    position: absolute;
    width: 50vw;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: top;
    top: 0;
    right: 0;
    &.contact-orange-curve {
      width: 60vw;
    }
  }
  @media only screen and (max-width: 770px) {
    img {
      width: 50vw;
    }
  }
}
