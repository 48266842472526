.result-list-items {
  padding: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: rgb(60, 60, 60);
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  .orange {
    color: #ff8d27;
  }
}
