.results,
.who-are-we {
  min-height: 90vh;
  padding: 100px 0;
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 0.5px solid #edecec2e;
  .heading {
    margin-bottom: 10px;
  }
}
