.who-are-we-curve {
  img {
    position: absolute;
    height: auto;
    bottom: 0;
    left: 0;
    max-width: 100vw;
    &:last-of-type {
      margin-bottom: -10%;
    }
  }
  @media only screen and (min-width: 430px) {
    img {
      height: 43vw;
      &:last-of-type {
        margin: 0;
      }
      &:first-of-type {
        margin-bottom: -5%;
        margin-left: -5%;
      }
    }
  }
}
