.heading {
  h1,
  h2,
  h3,
  h4 {
    color: #ff8d27;
    font-weight: 600;
  }
  h1 {
    font-size: 40px;
    line-height: 60px;
  }
  h2 {
    font-size: 35px;
    line-height: 50px;
  }
  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 22px;
  }
  .bold {
    &:first-child {
      font-weight: bold !important;
    }
  }
  @media only screen and (max-width: 900px) {
    h1,
    h2 {
      font-size: 34px;
      line-height: 44px;
    }

    h3,
    h4 {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media only screen and (max-width: 770px) {
    h1,
    h2 {
      font-size: 32px;
      line-height: 40px;
    }
    h3,
    h4 {
      font-size: 28px;
      line-height: 36px;
    }
  }
  @media only screen and (max-width: 600px) {
    h1,
    h2 {
      font-size: 23px;
      line-height: 30px;
    }
    h3,
    h4 {
      font-size: 18px;
      line-height: 25px;
    }
  }
}
