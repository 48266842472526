.contact-form {
  padding: 60px 0;
  .contact-button {
    height: 70px;
    border: none;
    background-color: #ff8d27;
    width: 100%;
    font-size: 25px;
    color: white;
    &.disabled {
      background-color: #ff8c278e;
    }
  }
  @media only screen and (min-width: 800px) {
    & {
      width: 75%;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 500px) {
    .contact-button {
      height: 50px;
      font-size: 18px;
    }
  }
}
